import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';

// Asynchronous thunk action
export const fetchProfile = createAsyncThunk(
  'profile/fetchProfile',
  async () => {
    const query = `
      query userProfilesQuery($id: ID!) {
    user(id: $id) {
      ... on User {
        _id
        firstName
        lastName
        otherNames
        email
        signupMethods
        twoFactorAuth {
          enabled
          method
        }
        location
        telephone
        profiles {
          _id
          locale
          profilePhoto
          defaultPortal
          isDefaultAccount
          accessedModules {
            name
            role
            tier
          }
          accountId
          accountRole
          accountInfo {
            _id
            name
            seats
            createdAt
            location
            creator
            website
            email
            info
            contact
            theme
            logoUrl
            logoShape
            isActive
            licenseInfo {
              licenseType
              licenseState
              dateOfExpiration
              discount {
                percentage
                validity
              }
              campaignId
            }
          }
          accessedPortals {
            portalId
            portalRole
            portalInfo {
              name
              toDeleteAfter30days
              dateOfDeletion
            }
          }
        }
      }
    }
  }
`;
    const authCode = Cookies.get('authCode');
    const decoded = jwt.decode(authCode);
    if (!decoded || !decoded.exp) {
      return;
    }
    const userId = decoded._id;

    const variables = {
      id: userId
    };

    try {
      const { data } = await axios({
        url: process.env.REACT_APP_MAIN_SERVER_URL,
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authCode}`
        },
        data: {
          query,
          variables
        }
      });

      return data.data.user;
    } catch (error) {
      return;
    }
  }
);

// Slice
const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    accessedModules: [],
    isModuleAccessible: false,
    isActive: true,
    selectedProfile: {},
    status: 'idle'
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        if (
          action.payload &&
          action.payload.profiles &&
          action.payload.profiles[0]
        ) {
          state.userData = action.payload;
          let activeProfile = Cookies.get('activeProfile');
          if (!activeProfile) {
            activeProfile = action?.payload?.profiles[0]?.accountId;
            const hostname = window.location.hostname;
            const mainDomain = hostname.match(/[^.]+\.(.+\..+)/);
            let usedDomain = hostname;
            if (mainDomain) {
              usedDomain = mainDomain[1] || hostname;
            }
            Cookies.set('activeProfile', activeProfile, {
              domain: usedDomain
            });
            localStorage.setItem('company-id', activeProfile);
          }
          const profileId = activeProfile?.split('-')[0];
          const currentProfile = action.payload.profiles.find(
            (profile) => profile.accountId === profileId
          );
          if (currentProfile) {
            const mPublisherModule = currentProfile?.accessedModules.find(
              (module) => module.name === 'mpublisher'
            );
            const expeirationDate = currentProfile?.accountInfo?.licenseInfo?.dateOfExpiration
            const isActive = currentProfile?.accountInfo?.isActive;
            state.dateOfExpiration = expeirationDate;
            state.isActive = isActive;
            const expirationDate = new Date(state.dateOfExpiration);
            const currentDate = new Date();
            if (expirationDate > currentDate && isActive) {
              state.accessedModules =
                currentProfile?.accessedModules;
              state.selectedProfile = currentProfile;
              state.currentCompanyInfo = currentProfile?.accountInfo;
              state.isModuleAccessible =
                currentProfile?.accessedModules?.some(
                  (module) => module.name === 'mpublisher'
                );
              const tier = mPublisherModule?.tier || 'Lite';
              localStorage.setItem('company-id', currentProfile?.accountId);
              localStorage.setItem('tier', tier);
            }
          } else {
            const madeActive = action.payload?.profiles[0];
            const expeirationDate = currentProfile?.accountInfo?.licenseInfo?.dateOfExpiration
            const isActive = currentProfile?.accountInfo?.isActive;
            state.dateOfExpiration = expeirationDate;
            state.isActive = isActive;
            const expirationDate = new Date(state.dateOfExpiration);
            const currentDate = new Date();
            if (expirationDate > currentDate && isActive) {
              state.accessedModules = madeActive?.accessedModules;
              state.isModuleAccessible =
                madeActive?.accessedModules?.some(
                  (module) => module.name === 'mpublisher'
                );
              state.selectedProfile = madeActive;
              state.currentCompanyInfo = madeActive?.accountInfo;
              const tier = madeActive?.accessedModules?.find(
                (module) => module.name === 'mpublisher'
              )?.tier;
              localStorage.setItem('tier', tier);
              localStorage.setItem('company-id', madeActive?.accountId);
            }
          }
        }
        state.status = 'succeeded';
      })
      .addCase(fetchProfile.rejected, (state) => {
        state.status = 'failed';
      });
  }
});

export default profileSlice.reducer;
